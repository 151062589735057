import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import 'swiper/css'
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import img1 from '../images/slider/1.jpg';
import img2 from '../images/slider/2.jpg';
import img3 from '../images/slider/3.jpg';
import img4 from '../images/slider/4.jpg';
import img5 from '../images/slider/5.jpg';
import img6 from '../images/slider/6.jpg';
import img7 from '../images/slider/7.jpg';
import img8 from '../images/slider/8.jpg';
import styled from 'styled-components';
import { devices } from '../Device';
import { InView } from 'react-intersection-observer';

const SliderContainer = styled.div`
    position: relative;
    height: 80%;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #CCC;
    @media ${devices.mobileM} {
        height: 50vh;
    }
    @media (max-width: 768px) and (orientation:landscape){
        height: 85vh;
    }
`

const Sliders = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    @media ${devices.mobileM} {
        background-position: center;
    }
    @media (min-width: 320px) and (max-width: 968px) and (orientation:landscape) {
        background-position: center;
    }
    @media (min-width: 768px) and (max-width: 967px) {
        background-position: center;
    }
    @media (min-width: 978px) and (max-width: 1140px){
        background-position: center bottom;
    }

    &.y80{
        background-position-y: 80% !important;
    }
`


const Slider = () => {
  const swiperRef = useRef()

    return (
        <SliderContainer>
          <InView onMouseOver={() => swiperRef.current.swiper.autoplay.start()}
            onMouseLeave={() => swiperRef.current.swiper.autoplay.stop()}>
                <Swiper
                    modules={[Navigation, Pagination, A11y, Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                    speed={800}
                    autoplay={false}
                    observer={'true'}
                    ref={swiperRef}
                    
                >
                    <SwiperSlide>
                      <Sliders className='y80' style={{backgroundImage: `url("${img1}")`}} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Sliders style={{backgroundImage: `url("${img2}")`}} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Sliders className='bottom' style={{backgroundImage: `url("${img3}")`}} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Sliders style={{backgroundImage: `url("${img4}")`}} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Sliders style={{backgroundImage: `url("${img5}")`}} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Sliders style={{backgroundImage: `url("${img6}")`}} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Sliders style={{backgroundImage: `url("${img7}")`}} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Sliders style={{backgroundImage: `url("${img8}")`}} />
                    </SwiperSlide>
                </Swiper>
            </InView>
        </SliderContainer>
        
    )
}

export default Slider