import React from 'react'
import styled from 'styled-components'
import banner from '../images/banner.jpg'
import LogoMain from '../images/logo/obs-blue.png'
import WA from '../images/icon/whatsapp.png'
import { devices } from '../Device'

const Section = styled.div`
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 92vh;
    @media ${devices.mobileM} {
        height: 100%;
    }

    @media (max-width: 768px) and (orientation:landscape){
        height: 96vh;
    }

    @media (min-width: 320px) and (max-width: 968px) and (orientation:landscape) {
        height: 96vh;
    }

    @media (min-width: 1366px) and (max-width: 1499px) {
        height: 88vh;
    }

    @media (min-width: 970px) and (max-width: 1366px) and (orientation:landscape){
        height: 80vh;
    }
`
const Banner  = styled.div`
    position: relative;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background-image:url(${banner});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% -55px;

    @media ${devices.mobileM} {
        height: 70vh;
        background-position-x: -50px;
        background-position-y: bottom;
    }

    @media (max-width: 768px) and (orientation:landscape){
        height: 100%;
        background-position: center -30px;
    }

    @media (min-width: 320px) and (max-width: 968px) and (orientation: landscape){
        height: 100%;
        background-position: center -30px;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation:portrait) {
        background-position-x: left;
        background-position-y: center;
    }

    @media (min-width: 1366px) and (max-width: 1499px) { 
        height: 100vh;
        background-position-x: center;
        background-position-y: -20px;
    }

    @media (min-width: 970px) and (max-width: 1366px) and (orientation:landscape){
        height: 95vh;
        background-position-x: center;
    }
    
`
const Overlay = styled.div`
    position: absolute;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.2);
`

const Box = styled.div`
    display:flex;
    flex-direction: column;
`


const LogoContainer = styled.div`
    display: flex;
    flex: 1;
    margin: 4em 0 0.5em 0;
    justify-content: center;
    align-items: center;

    @media ${devices.mobileM} {
        margin: 2em 0 0.5em 0;
    }

    @media (min-width: 320px) and (max-width: 968px) and (orientation:landscape) {
        margin: 2em 0 0.5em 0;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation:portrait) {
        margin: 5em 0 0.5em 0;
    }

    @media (min-width: 970px) and (max-width: 1366px) and (orientation:landscape) {
        margin: 3em 0 0.5em 0;
    }

`

const Logo = styled.img`
    width: 180px;

    @media ${devices.mobileM} {
        width: 120px;
    }

    @media (min-width: 320px) and (max-width: 968px) and (orientation:landscape) {
        width: 100px;
    }

    @media (min-width: 2650px) and (max-width:	3840px) {
        width: 220px;
    }

    @media only screen (min-width: 768px) and (max-width: 1366px) and (orientation:landscape) {
        width: 380px;
    }


`


const IconWaContainer = styled.a`
    position: fixed;
    right: -10px;
    bottom: 10%;
    margin: 4em 0em;
    height: 100px;
    width: 100px;
    z-index: 9999;
    cursor: pointer;
    @media ${devices.mobileM} {
        height: 70px;
        width: 70px;
    }

    @media (min-width: 320px) and (max-width: 968px) and (orientation:landscape) {
        height: 70px;
        width: 70px;
    }

    
`

const WaIcon = styled.img`
    height: 100%;
    width: auto;
`


const Header = () => {
  return (
    <Section id="home">
        <Banner>
            <Overlay>
                <Box>
                    <LogoContainer>
                        <Logo src={LogoMain} alt="img-logo"></Logo>
                    </LogoContainer>
                </Box>
            </Overlay>
            <IconWaContainer href="https://wa.me/+6281289021035" target="_blank">
                <WaIcon src={WA} alt="img-wa" />
            </IconWaContainer>
        </Banner>
    </Section>
  )
}

export default Header