import React from 'react'
import styled from 'styled-components'
import { devices } from '../Device'

const Container = styled.div`
    height: 60vh;
    width: 100%;
    display: flex;
    margin: 0 0;
    padding: 0;
    @media ${devices.mobileM} {
      height: 35vh
    }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation:portrait) {
      height: 35vh
    }

    @media (min-width: 820px) and (max-width: 1200px) and (orientation:portrait) {
      height: 35vh
    }

    @media (min-width: 320px) and (max-width: 968px) and (orientation:landscape) {
      height: 75vh
    }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation:landscape) {
      height: 75vh
    }
`

const BackImage = styled.div`
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media ${devices.mobileM} {
        background-position: center;
        background-repeat: no-repeat;
    }
`

const SectionImage = ({img}) => {
  return (
    <Container>
      <BackImage className="bg-mobile-left" style={{backgroundImage: `url("${img}")`}} />
    </Container>
  )
}

export default SectionImage