import React, { useEffect, useState } from 'react'
import { slide as Menu } from "react-burger-menu";
import {Link} from 'react-scroll'
import styled from 'styled-components';
import { devices } from '../Device';
import MiniLogo from '../images/logo/mini-logo-white.png'

const LogoMini = styled.div`
    height: 45px;
    margin: 1.5em auto 0 auto;
    width: 60%;
    padding: 3px;

    @media ${devices.mobileM} {
        height: 35px;
        width: 40%;
    
    }

 
`
const LogoBack = styled.div`
    height: 100%;
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat
`

const MenuPopup = ({props}) => {

    const [isOpen, setOpen] = useState(false)

    const handleIsOpen = () => {
        setOpen(!isOpen)
    }

    const closeSideBar = () => {
        setOpen(false)
    }
      
    function scrollClose() {
        setOpen(false);
    }
  
    useEffect(() => {
      function watchScroll() {
        window.addEventListener("scroll", scrollClose);
      }
      watchScroll();
      return () => {
        window.removeEventListener("scroll", scrollClose);
      };
    }, []);

    return (
        <>
            <Menu {...props} isOpen={isOpen} onOpen={handleIsOpen} onClose={handleIsOpen}>
                <Link onClick={closeSideBar} to="home" spy={true} smooth={true} href="/" className='menu-item'>HOME</Link>
                <Link onClick={closeSideBar} to="invest" spy={true} smooth={true} href="/" className='menu-item'>REASON TO INVEST</Link>
                <Link onClick={closeSideBar} to="services" spy={true} smooth={true} href="/" className='menu-item'>OUR SERVICES</Link>
                <Link onClick={closeSideBar} to="why" spy={true} smooth={true} href="/" className='menu-item' >WHY ORMAND</Link>
                <Link onClick={closeSideBar} to="contact" spy={true} smooth={true} href="/" className='menu-item'>CONTACT</Link>
                <LogoMini>
                    <LogoBack style={{backgroundImage: `url("${MiniLogo}")`}} />
                </LogoMini>
            </Menu>
            
        </>
    )
}

export default MenuPopup