import React from 'react'
import styled from 'styled-components'
import Header from './components/Header'
import SectionImage from './components/SectionImage'
import ImgBannerSmall from './images/banner-small.jpg';
import ImgBannerSmall2 from './images/banner-small-2.jpg';
import ImgBannerSmallCorp from './images/banner-small-corp.jpg';

import { devices } from './Device'
import Invest from './components/Invest';
import Slider from './components/Slider';
import AccordionList from './components/AccordionList';
import Footer from './components/Footer';
import MenuPopup from './components/MenuPopup';
import SectionImageLarge from './components/SectionImageLarge';

const MenuContainer = styled.div`
    position: fixed;
    top: 0;
    height: auto;
    width: auto;
    z-index: 999;
`

const TextContainer = styled.div`
    position: relative;
    padding: 1.8em 0em 3em 0em;
    height: auto;
    background: #FFF;

    @media ${devices.mobileM} {
        font-size: 14px;
        margin: 0 2.2em;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation:portrait) {
        padding: 1em 3.5em;
    }

    @media (min-width: 320px) and (max-width: 968px) and (orientation:landscape) {
        padding: 1em 3em;
    }

    @media (min-width: 970px) and (max-width: 1366px) and (orientation:landscape) {
        padding: 1.8em 3em 3em 3em;
    }

    @media (min-width: 820px) and (max-width: 1200px) and (orientation:landscape) {
      padding: 1em 3em;
    }

    @media screen ${devices.tablet} {
        padding: 2em 1em;
        font-size: 14px;
    }

    @media (min-width: 1366px) and (max-width: 1499px) {
      padding: 1.8em 3em 3em 3em;
    }

`

const TextHead = styled.h1`
    font-size: 40px;
    font-family: 'Gentlement-Light';
    color: #002C40;
    text-align: center;
    letter-spacing: 1px;
    margin: 0.5em 0 0.67em auto;
    @media ${devices.mobileM} {
      font-size: 28px;
    }
    @media (min-width: 320px) and (max-width: 968px) and (orientation:landscape) {
      font-size: 28px;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation:landscape) {
      font-size: 28px;
    }
`
const TextDetail = styled.p`
    font-family: 'Gentlement-Brook';
    font-size: 20px;
    text-align: justify;
    text-align-last: center;
    letter-spacing: 0;
    margin: 0 8em 1.25em 8em;
    line-height: 1.1;
    color: #002C40;
    font-weight: bold;
    @media ${devices.mobileM} {
      font-size: 15.5px;
      margin: 0 0em 1.5em 0em;
      text-align: center;
      text-align-last: center;
    }
    @media (min-width: 320px) and (max-width: 968px) and (orientation:landscape) {
      font-size: 15.5px;
      margin: 0 0em 1.5em 0em;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation:portrait) {
      font-size: 17.5px;
      margin: 0 0em 1.5em 0em;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation:landscape) {
      font-size: 17.5px;
      margin: 0 0em 1.5em 0em;
    }

    @media (min-width: 820px) and (max-width: 1200px) and (orientation:landscape) {
      margin: 0 0em 1.5em 0em;
    }

`

const TextDetailContainer = styled.div`
    position: relative;
    width: 75%;
    margin: 0 auto;

    @media ${devices.mobileM} {
      width: 100%;
    }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation:portrait) {
      width: 100%;
    }
    @media (min-width: 820px) and (max-width: 1200px) and (orientation:landscape) {
      width: 100%;
    }

  }

`
const TextTagline = styled.h4`
    font-size: 28px;
    font-family: 'Gentlement-Brook';
    color: #002C40;
    text-align: center;
    margin: 1.8em 0 1.6em 0;

    @media ${devices.mobileM} {
      font-size: 20px;
    }
    
    @media (min-width: 320px) and (max-width: 968px) and (orientation:landscape) {
      font-size: 20px;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation:landscape) {
      font-size: 20px;
    }

`

const App = () => {
  return (
    <>
      <MenuContainer>
          <MenuPopup pageWrapId={"page-wrap"} outerContainerId={"App"} />
      </MenuContainer>
      <Header />
      <TextContainer>
        <TextHead>CHOOSING SINGAPORE FOR YOUR BUSINESS</TextHead>
        <TextDetailContainer>
          <TextDetail>Singapore is a city-state located at the southern tip of the Malay Peninsula. During the 1960s and 1990s, Singapore underwent rapid industrialization and maintained an exceptionally high economic growth rate, earning its reputation as Asia's Little Dragon.</TextDetail>
          <TextDetail>Today, Singapore has established itself as a global business hub and regional hub for startups.</TextDetail>
          <TextDetail>Besides offering grants, loans, or tax incentives to startups, the Singapore government has introduced various
          initiatives and programs to create a vibrant startup ecosystem for local and foreign entrepreneurs.</TextDetail>
          <TextDetail>The country's well-developed infrastructure, stable political environment, robust economy, and skilled workforce
          are just some of the reasons why many multinationals and foreign entrepreneurs chose to set up their
          businesses in Singapore.</TextDetail>
        </TextDetailContainer>
      </TextContainer>
      <SectionImage img={ImgBannerSmall} />
      <Invest id="invest" />
      <SectionImage img={ImgBannerSmall2} />
      <TextContainer>
        <TextHead>HOW WE CAN HELP YOU SETTING<br /> YOUR BUSINESS</TextHead>
        <TextTagline>WE ARE A WORLD-CLASS ONE STOP CONCIERGE SERVICE</TextTagline>
        <TextDetailContainer>
          <TextDetail>
            if you are considering expanding but want to save budget and time and ensure you are compliant with local protocols, 
            our full-fledged concierge service will help you set everything easily. Whether it is about your setup or a business one, you can avoid all complications, reduce paperwork, 
            and have experts advising you on compliance to realize your expansion plans. Additionally, our Wisma Atria office is located in the heart of Singapore, 
            allowing you to showcase your company with a prestigious address and take advantage of every opportunity.
          </TextDetail>
        </TextDetailContainer>
      </TextContainer>
      <Slider />
      <TextContainer>
        <TextHead id="services">OUR SERVICES</TextHead>
      </TextContainer>
      <AccordionList />
      <SectionImageLarge img={ImgBannerSmallCorp} />
      <TextContainer>
        <TextHead id="why">REASONS TO CHOOSE <br />ORMAND BUSINESS SOLUTIONS</TextHead>
        <TextDetailContainer>
          <TextDetail>
            Ormand Corp has more than ten years of experience in many industries. 
          </TextDetail>
          <TextDetail>Our deep and vast knowledge in these fields allows us to understand your needs in opening your business in Singapore.</TextDetail>
          <TextDetail>
            Ormand Business Solutions has offices and professional team in both Singapore and Indonesia, 
            so you can feel more secure that wherever you are as we will be able to help you in all aspects of the economy and policies of both countries.
          </TextDetail>
          <TextDetail>
            Our professional team is accommodating and has deep expertise in their field, so you can rest assured on every step you make.
          </TextDetail>
          <TextDetail>We give you a seamless incorporation process without needing you to be present in Singapore.</TextDetail>
          <TextDetail>We provide more thorough facilities in every part of our services.</TextDetail>
          <TextDetail>Our Personal Assistant will provide you or your client personalized services on any business you need when you or your client arrive in Singapore.</TextDetail>

        </TextDetailContainer>
       
      </TextContainer>
      <Footer />
    </>
  )
}

export default App