import React, { Children, cloneElement, useState } from 'react'
import parse from 'html-react-parser'
import useCollapse from 'react-collapsed';
import styled from 'styled-components';
import { devices } from '../Device';

const List = [
    {
        id: 1, 
        textHead: "INCORPORATION",
        title: "We will help you incorporate your company in Singapore without needing your presence in Singapore.",
        panel: "<ul><li>Incorporation of Company</li><li>Resident Director</li><li>Resident Joint Company Secretaries</li><li>Nominee Shareholder(s)</li><li>Bank Signatory</li></ul>",
        table: '',
        buttonShow: "",
        status: ''

    },
    {
        id: 2, 
        textHead: "CORPORATE SECRETARY",
        title: "Our expert team will help you with all aspects of your company's legal needs.",
        panel: "<ul><li>Accounting & Financial Reporting Services</li><li>Tax Compliance & Advisory Service</li></ul>",
        table: '',
        buttonShow: "",
        status: ''

    },
    {
        id: 3, 
        textHead: "VIRTUAL OFFICE",
        title: "Just focus on essential things in your life while we help you with your administrative work or lifestyle management.",
        panel: "<ul><li>Professional Meeting Room</li><li>Business Lounge</li><li>Business Address and local phone number</li><li>Professional Receptionist</li><li>High-speed Wi-Fi</li><li>Access to video-conferencing and online meeting</li></ul>",
        table: '',
        buttonShow: "",
        status: ''

    },
    {
        id: 4, 
        textHead: "PERSONAL ASSISTANT",
        title: " A personal assistant could be there on your call to help you or your client with any business you seek in Singapore.",
        panel: "",
        table: '',
        buttonShow: "hidden",
        status: 'false'

    },
    {
        id: 5, 
        textHead: "CONCIERGE",
        title: "We would link you up and manage your appointment with other services and companies for your personal and corporate matters.",
        panel: "<ul><li>Education Related Services </li><li>Medical Related Services</li><li>Real Estate Related Services</li><li>Financial Related Services</li><li>Notary Related Services</li></ul>",
        table: '',
        buttonShow: "",
        status: ''

    }

]

const Container = styled.div`
    position: relative;
    height: auto;
    width: 50%;
    margin: 0 auto;
    padding: 0;
    top: -0.85em;
    @media ${devices.mobileM} {
        width: 100%;
        margin: 1em auto;
        padding: 0 2em;
    }

    @media (min-width: 320px) and (max-width: 968px) and (orientation:landscape) {
        width: 75%;
    }

    
    @media (min-width: 1366px) and (max-width: 1499px) {
        width: 75%;
    }
    
    @media (min-width: 768px) and (max-width: 1024px) and (orientation:portrait) {
        width: 75%;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation:landscape) {
        width: 75%;
    }

    @media (min-width: 820px) and (max-width: 1200px) and (orientation:landscape) {
        width: 75%;
    }
    
`

const Accordion = styled.div`
    font-family: 'Gentlement-Reg';
`
const AccordionItem = styled.div`
    margin-bottom: 0.8em; 
    background-color: #DCE5EA;
    padding: 1.2em 8em;
    @media ${devices.mobileM} {
        padding: 0.8em 0.5em;
    }
    @media (max-width: 768px) and (orientation:landscape){
        padding: 0.8em 1.2em;
    }
    @media (min-width: 320px) and (max-width: 968px) and (orientation:landscape) {
        padding: 1.2em 4em;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation:landscape) {
        padding: 1.2em 4em;
    }
    

`
const AccordionItemHeading = styled.div`
    &.false{
        pointer-events: none !important;
        cursor: not-allowed;
    }
    &.false > [type="button"]{

    }
`
const AccordionItemButton = styled.div`
    color: #002C40;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;

    @media ${devices.mobileM} {
        padding: 0.8em 0.5em;
    }

    $:hover{
        background-color: #DCE5EA;
    }

    
  
`

const AccordionItemPanel = styled.div`
    background-color: #DCE5EA;
    width: 100%;
    @media ${devices.mobileM} {
        padding: 0em 0.5em;
    }
    @media (max-width: 768px) and (orientation:landscape){
        padding: 0em 1em;
    }
    @media (min-width: 320px) and (max-width: 968px) and (orientation:landscape) {
        padding: 0em 0.5em;
    }

`

const AccHeader = styled.h3`
    font-size: 24px;
    text-transform: uppercase;
    margin: 0 0 1em 0;
    letter-spacing: 1px;
    @media ${devices.mobileM} {
        font-size: 18px;
    }
    @media (min-width: 320px) and (max-width: 968px) and (orientation:landscape) {
        font-size: 18px;
    }
`

const AccText = styled.p`
    font-size: 17.5px;
    line-height: 22px;
    @media ${devices.mobileM} {
        font-size: 12.5px;
        margin-right: 3em;
    }
    @media (min-width: 320px) and (max-width: 968px) and (orientation:landscape) {
        font-size: 12.5px;
    }
`
const TextPanel = styled.div`
    font-size: 17.5px;
    line-height: 22px;
    color: #002C40;
    margin: 1em 0px 1.2em 1em;
    @media ${devices.mobileM} {
        font-size: 12.5px;
        margin-right: 3em;
    }

    @media (min-width: 320px) and (max-width: 968px) and (orientation:landscape) {
        font-size: 12.5px;
    }

    & ul {
        list-style-type: disc;
    }

    & p{
        margin: 0 !important;
    }
`

const Collapse = ({ isActive, onSelect, title, children, textHead, show, unclick }) => {
    const { getCollapseProps, getToggleProps } = useCollapse({
      isExpanded: isActive
    })
  
    return (
      <AccordionItem>
          <AccordionItemHeading className={unclick}>
                <AccordionItemButton
                  {...getToggleProps({
                    onClick: onSelect,
                  })}
                >
                  <AccHeader>{textHead}</AccHeader>
                  <AccText>
                      {title}
                  </AccText>
                  <div className={show}>
                  {isActive ? <div className='accordion_arrow hidden'></div> :<div className='accordion_arrow' ></div> }
                  </div>
              </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel {...getCollapseProps()}>
                {children}
                <div className='accordion_arrow_up' {...getToggleProps({
                  onClick: onSelect
                })}></div>
          </AccordionItemPanel>
      </AccordionItem>
    )
  }
  
const AccordionParent = ({ children }) => {
    const [activeIndex, setActiveIndex] = useState(null)
    return (
      <Accordion>
          {Children.map(children, (child, index) =>
              cloneElement(child, {
              ...child,
              isActive: activeIndex === index,
              onSelect: () => setActiveIndex(activeIndex === index ? null : index)
              })
          )}
      </Accordion>
    )
}
  

const AccordionList = () => {
    return (
        <Container>
            <AccordionParent>
                {List.map(({id, textHead, title, panel, buttonShow, status }) => (
                    <Collapse key={id} title={parse(title)} textHead={textHead} show={buttonShow} unclick={status}><TextPanel>{parse(panel)}</TextPanel></Collapse>
                ))}
            </AccordionParent>
        </Container>
    );
}

export default AccordionList