const sizes = {
    mobileS: '320px',
    mobileM: '768px',
    mobileL: '425px',
    tablet: '43 rem',
    ipad: '62 rem',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
    land: 'Landscape',
    port: 'Portrait'
};

export const devices = {
    mobileS: `(max-width: ${sizes.mobileS})`,
    mobileM: `(max-width: ${sizes.mobileM})`,
    mobileMLand: `(max-width: ${sizes.ipad}) and (orientation: ${sizes.land})`,
    mobileL: `(max-width: ${sizes.mobileL})`,
    mobileBigLand: `(min-width: ${sizes.mobileS}) and (max-width: ${sizes.ipad}) and (orientation: ${sizes.land})`,
    tablet: `(min-width: ${sizes.tablet}) && (max-width: ${sizes.ipad})`,
    laptop: `(max-width: ${sizes.laptop})`,
    laptopL: `(max-width: ${sizes.laptopL})`,
    desktop: `(max-width: ${sizes.desktop})`,
};