import React from 'react'
import styled from 'styled-components'
import LogoWhite from '../images/logo/obs-white.png'
import LogoCorp from '../images/logo/ormand-corp-white.png'

import { devices } from '../Device'


const FooterContainer = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #002C40;
    padding: 4em 0 5em 0
`
const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    height: auto;
    margin: 0 0 3em 0;
`
const Logo = styled.img`
    width: 220px;

    @media ${devices.mobileM} {
        width: 140px;
    }

    @media (min-width: 320px) and (max-width: 968px) and (orientation:landscape) {
        width: 120px;
    }

    @media (min-width: 2650px) and (max-width:	3840px) {
        width: 380px;
    }

    @media only screen (min-width: 768px) and (max-width: 1366px) and (orientation:landscape) {
        width: 380px;
    }

`

const TextContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
`

const TextAddress = styled.h3`
    font-size: 14px;
    font-family: 'Gentlement-ExLight';
    text-align: center;
    font-weight: bold;
    margin: 0.6em 16em;
    color: #FFFFFF;
    bottom: 1em;
    @media ${devices.mobileM} {
      margin: 0.6em 0;
      font-size: 13px;
    }

    &.t20{
      margin-top: 2.5em;
    }

`

const TextPhone = styled.h3`
  font-size: 14px;
  font-family: 'Gentlement-ExLight';
  text-align: center;
  font-weight: bold;
  margin: 0.6em 16em;
  color: #FFFFFF;
  @media ${devices.mobileM} {
    margin: 0.6em 0;
  }
`
const TextBottom = styled.div`
  margin-top: 3em;
  font-size: 13px;
  font-family: 'Gentlement-Light';
  text-align: center;
  margin: 6em 0 0 0 ;
  color: #FFFFFF;
  text-decoration: none;
`

const TextLink = styled.a`
  text-decoration: none;

`

const TextCopy = styled.p`
  color: #FFF;
  font-family: 'Gentlement-Light';
  font-size: 12px;
  text-align: center;
  letter-spacing: 1px;
  text-decoration: none;
`

const Footer = () => {
  return (
    <FooterContainer id="contact">
      <LogoContainer>
        <Logo src={LogoWhite} alt="obs-white"></Logo>
      </LogoContainer>
      <TextContainer>
        <TextAddress>
          435 ORCHARD ROAD<br />
          WISMA ATRIA OFFICE TOWER #18-06,<br />
          SINGAPORE 238877
        </TextAddress>
        <TextPhone>+65 6908 5000 </TextPhone>
        <TextAddress className='t20'>
          32ND FLOOR CENTENNIAL TOWER<br />
          JL GATOT SUBROTO KAV. 24 - 25<br />
          JAKARTA 12930
        </TextAddress>
        <TextPhone>+6221 8665 5000 </TextPhone>
        <TextBottom>
          <TextAddress>A MEMBER OF</TextAddress>
          <TextLink href="https://ormandcorp.com/" target="_blank">
            <Logo src={LogoCorp} alt="ormand-corp"></Logo>
            <TextCopy>www.ormandcorp.com</TextCopy>
          </TextLink>
        </TextBottom>
      </TextContainer>
    
    </FooterContainer>
  )
}

export default Footer